import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isPaid
    ? _c("div", { staticStyle: { height: "100%" } }, [_vm._t("default")], 2)
    : _c(
        "div",
        {
          on: {
            click: function ($event) {
              return _vm.recordUsage()
            },
          },
        },
        [
          _vm.loading
            ? _c(VProgressLinear, {
                attrs: { indeterminate: "", color: "green" },
              })
            : _vm._e(),
          _c(
            "div",
            { staticClass: "text-blur", style: _vm.blurStyle },
            [
              _c(
                VRow,
                {
                  staticStyle: {
                    position: "absolute",
                    top: "5px",
                    right: "5px",
                    "z-index": "999",
                  },
                  attrs: { dense: "", justify: "end" },
                },
                [
                  _c(VIcon, { attrs: { small: "", color: "white" } }, [
                    _vm._v("fas fa-lock"),
                  ]),
                ],
                1
              ),
              _vm._t("default"),
            ],
            2
          ),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListGroup } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        VContainer,
        {
          staticStyle: {
            position: "fixed",
            width: "100%",
            height: "91.5vh",
            "overflow-y": "auto",
          },
          attrs: { fluid: "" },
        },
        [
          _c(
            VRow,
            {
              staticStyle: { "margin-top": "-20px" },
              attrs: { "no-gutters": "" },
            },
            [
              _c(
                VCol,
                { attrs: { cols: _vm.isMobile ? 0 : 3 } },
                [
                  _vm.classInfo
                    ? _c(
                        VNavigationDrawer,
                        {
                          staticStyle: { height: "calc(100vh - 65px)" },
                          style: _vm.isMobile
                            ? "width: 65%; margin-top: 56px;"
                            : "width: 25%; margin-top: 65px;",
                          attrs: {
                            permanent: !_vm.isMobile,
                            fixed: true,
                            temporary: _vm.isMobile,
                            "hide-overlay": "",
                          },
                          model: {
                            value: _vm.drawer,
                            callback: function ($$v) {
                              _vm.drawer = $$v
                            },
                            expression: "drawer",
                          },
                        },
                        [
                          _c(
                            VListItem,
                            [
                              _c(
                                VTooltip,
                                {
                                  attrs: { bottom: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on }) {
                                          return [
                                            _c(
                                              VIcon,
                                              _vm._g(
                                                {
                                                  staticStyle: {
                                                    "margin-right": "10px",
                                                  },
                                                  attrs: {
                                                    color:
                                                      _vm.editModeController ==
                                                      "openClassInfoCard"
                                                        ? "primary"
                                                        : "",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.openClassInfo()
                                                    },
                                                  },
                                                },
                                                on
                                              ),
                                              [_vm._v("mdi-home")]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    1696103477
                                  ),
                                },
                                [_c("span", [_vm._v(" Home ")])]
                              ),
                              _vm.classInfo && !_vm.isMobile
                                ? _c(
                                    VListItemContent,
                                    [
                                      _c(VListItemTitle, [
                                        _c("b", [
                                          _vm._v(
                                            _vm._s(_vm.classInfo.className)
                                          ),
                                        ]),
                                      ]),
                                      _c(VListItemSubtitle, [
                                        _vm._v(_vm._s(_vm.classInfo.classId)),
                                      ]),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "div",
                                [
                                  _vm.isAdmin
                                    ? _c(
                                        VTooltip,
                                        {
                                          attrs: { bottom: "" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function ({ on }) {
                                                  return [
                                                    _c(
                                                      VIcon,
                                                      _vm._g(
                                                        {
                                                          staticClass:
                                                            "ismobile-mdi-pencil",
                                                          staticStyle: {
                                                            "margin-left":
                                                              "10px",
                                                          },
                                                          attrs: {
                                                            color:
                                                              _vm.editModeController ==
                                                              "inputLabinfo"
                                                                ? "primary"
                                                                : "",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.addGrouporLab()
                                                            },
                                                          },
                                                        },
                                                        on
                                                      ),
                                                      [
                                                        _vm._v(
                                                          "mdi-pencil\n                                    "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            2973133190
                                          ),
                                        },
                                        [
                                          _c("span", {
                                            domProps: {
                                              textContent: _vm._s(_vm.ModeText),
                                            },
                                          }),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(VDivider),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "padding-bottom": "200px",
                                "overflow-y": "auto",
                                height: "calc(100% - 65px)",
                              },
                            },
                            [
                              _vm.editModeController == "inputLabinfo"
                                ? _c(
                                    VList,
                                    { attrs: { dense: "", expand: "" } },
                                    [
                                      _c(
                                        "draggable",
                                        {
                                          attrs: {
                                            list: _vm.copyClassInfoforGroupList
                                              .groupedLabsList,
                                          },
                                          on: { change: _vm.log },
                                        },
                                        _vm._l(
                                          _vm.copyClassInfoforGroupList
                                            .groupedLabsList,
                                          function (group, index) {
                                            return _c(
                                              VListGroup,
                                              {
                                                key: index,
                                                attrs: {
                                                  "no-action": "",
                                                  value:
                                                    index == 0 ? true : false,
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "prependIcon",
                                                      fn: function () {
                                                        return [
                                                          group.groupName !=
                                                            "uncategorized" &&
                                                          group.labsList
                                                            .length == 0
                                                            ? _c(
                                                                VListItemIcon,
                                                                {
                                                                  staticStyle: {
                                                                    margin:
                                                                      "0px",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    VIcon,
                                                                    {
                                                                      attrs: {
                                                                        color:
                                                                          "error",
                                                                        small:
                                                                          "",
                                                                      },
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.openDeleteGroupCard(
                                                                              group,
                                                                              index
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                                                mdi-minus-circle\n                                            "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      },
                                                      proxy: true,
                                                    },
                                                    {
                                                      key: "activator",
                                                      fn: function () {
                                                        return [
                                                          _c(
                                                            VListItemTitle,
                                                            {
                                                              style:
                                                                group.groupName !=
                                                                "uncategorized"
                                                                  ? group
                                                                      .labsList
                                                                      .length ==
                                                                    0
                                                                    ? "margin-left: -30px;"
                                                                    : ""
                                                                  : "",
                                                              domProps: {
                                                                textContent:
                                                                  _vm._s(
                                                                    group.groupName
                                                                  ),
                                                              },
                                                            }
                                                          ),
                                                        ]
                                                      },
                                                      proxy: true,
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                              },
                                              [
                                                _c(
                                                  "draggable",
                                                  {
                                                    staticClass: "list-group",
                                                    attrs: {
                                                      list: group.labsList,
                                                      group: "people",
                                                    },
                                                    on: { change: _vm.log },
                                                  },
                                                  _vm._l(
                                                    group.labsList,
                                                    function (labId, i) {
                                                      return _c(
                                                        VListItem,
                                                        {
                                                          key: i,
                                                          staticStyle: {
                                                            cursor: "move",
                                                          },
                                                          attrs: { link: "" },
                                                        },
                                                        [
                                                          _c(
                                                            VListItemTitle,
                                                            {
                                                              key: _vm.updateLabsList,
                                                              staticStyle: {
                                                                "margin-left":
                                                                  "20px",
                                                              },
                                                            },
                                                            [
                                                              _vm.AlllabsList[
                                                                group.groupName
                                                              ] &&
                                                              _vm.AlllabsList[
                                                                group.groupName
                                                              ][labId]
                                                                ? _c("div", [
                                                                    _c(
                                                                      "li",
                                                                      {
                                                                        style:
                                                                          _vm
                                                                            .AlllabsList[
                                                                            group
                                                                              .groupName
                                                                          ][
                                                                            labId
                                                                          ]
                                                                            .active
                                                                            ? ""
                                                                            : "opacity: 0.4;",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n                                                        " +
                                                                            _vm._s(
                                                                              _vm
                                                                                .AlllabsList[
                                                                                group
                                                                                  .groupName
                                                                              ][
                                                                                labId
                                                                              ]
                                                                                .labName
                                                                            ) +
                                                                            "\n                                                        "
                                                                        ),
                                                                        _vm
                                                                          .AlllabsList[
                                                                          group
                                                                            .groupName
                                                                        ][labId]
                                                                          .price
                                                                          ? _c(
                                                                              VIcon,
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    small:
                                                                                      "",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  " mdi-lock-outline\n                                                        "
                                                                                ),
                                                                              ]
                                                                            )
                                                                          : _vm._e(),
                                                                        _vm
                                                                          .AlllabsList[
                                                                          group
                                                                            .groupName
                                                                        ][labId]
                                                                          .labStatus &&
                                                                        _vm
                                                                          .AlllabsList[
                                                                          group
                                                                            .groupName
                                                                        ][labId]
                                                                          .labStatus
                                                                          .status ==
                                                                          "completed"
                                                                          ? _c(
                                                                              VChip,
                                                                              {
                                                                                staticStyle:
                                                                                  {
                                                                                    "margin-left":
                                                                                      "5px",
                                                                                  },
                                                                                attrs:
                                                                                  {
                                                                                    color:
                                                                                      "green",
                                                                                    outlined:
                                                                                      "",
                                                                                    "x-small":
                                                                                      "",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "완료\n                                                        "
                                                                                ),
                                                                              ]
                                                                            )
                                                                          : _vm._e(),
                                                                        _vm
                                                                          .AlllabsList[
                                                                          group
                                                                            .groupName
                                                                        ][labId]
                                                                          .labStatus &&
                                                                        _vm
                                                                          .AlllabsList[
                                                                          group
                                                                            .groupName
                                                                        ][labId]
                                                                          .labStatus
                                                                          .status ==
                                                                          "started"
                                                                          ? _c(
                                                                              VChip,
                                                                              {
                                                                                staticStyle:
                                                                                  {
                                                                                    "margin-left":
                                                                                      "5px",
                                                                                  },
                                                                                attrs:
                                                                                  {
                                                                                    color:
                                                                                      "red",
                                                                                    outlined:
                                                                                      "",
                                                                                    "x-small":
                                                                                      "",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "진행중\n                                                        "
                                                                                ),
                                                                              ]
                                                                            )
                                                                          : _vm._e(),
                                                                        _vm
                                                                          .AlllabsList[
                                                                          group
                                                                            .groupName
                                                                        ][labId]
                                                                          .passMessage
                                                                          ? _c(
                                                                              VChip,
                                                                              {
                                                                                staticStyle:
                                                                                  {
                                                                                    "margin-left":
                                                                                      "5px",
                                                                                  },
                                                                                attrs:
                                                                                  {
                                                                                    color:
                                                                                      _vm
                                                                                        .AlllabsList[
                                                                                        group
                                                                                          .groupName
                                                                                      ][
                                                                                        labId
                                                                                      ]
                                                                                        .passMessage ==
                                                                                      "접속"
                                                                                        ? "blue darken-1"
                                                                                        : "green",
                                                                                    outlined:
                                                                                      "",
                                                                                    "x-small":
                                                                                      "",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "\n                                                            " +
                                                                                    _vm._s(
                                                                                      _vm
                                                                                        .AlllabsList[
                                                                                        group
                                                                                          .groupName
                                                                                      ][
                                                                                        labId
                                                                                      ]
                                                                                        .passMessage
                                                                                    ) +
                                                                                    "\n                                                        "
                                                                                ),
                                                                              ]
                                                                            )
                                                                          : _vm._e(),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ])
                                                                : _c("li", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        labId
                                                                      )
                                                                    ),
                                                                  ]),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    }
                                                  ),
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _c(
                                    VList,
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "flex-direction": "column",
                                        "max-width": "100%",
                                      },
                                      attrs: { dense: "", expand: "" },
                                    },
                                    _vm._l(
                                      _vm.classInfo.groupedLabsList,
                                      function (lab, i) {
                                        return _c(
                                          "div",
                                          { key: i },
                                          [
                                            lab && lab.labsList.length > 0
                                              ? _c(
                                                  VListGroup,
                                                  {
                                                    staticClass:
                                                      "lab-list-group-box",
                                                    attrs: { value: true },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.selectGroup(
                                                          lab
                                                        )
                                                      },
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function () {
                                                            return [
                                                              _c(
                                                                VListItemTitle,
                                                                [
                                                                  _c(
                                                                    "b",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          color:
                                                                            "black",
                                                                        },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          lab.groupName
                                                                        )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              ),
                                                              _vm.isLoadingLabInfo
                                                                ? _c(
                                                                    VIcon,
                                                                    {
                                                                      attrs: {
                                                                        color:
                                                                          "primary",
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "mdi-spin mdi-loading"
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                            ]
                                                          },
                                                          proxy: true,
                                                        },
                                                      ],
                                                      null,
                                                      true
                                                    ),
                                                  },
                                                  _vm._l(
                                                    lab.labsList,
                                                    function (groupedLab, i) {
                                                      return _c(
                                                        "div",
                                                        {
                                                          key: i,
                                                          staticStyle: {
                                                            display: "flex",
                                                            "max-width": "100%",
                                                          },
                                                        },
                                                        [
                                                          _vm.AlllabsList[
                                                            lab.groupName
                                                          ] &&
                                                          _vm.AlllabsList[
                                                            lab.groupName
                                                          ][groupedLab]
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticStyle: {
                                                                    "white-space":
                                                                      "nowrap",
                                                                  },
                                                                },
                                                                [
                                                                  _vm
                                                                    .AlllabsList[
                                                                    lab
                                                                      .groupName
                                                                  ][groupedLab]
                                                                    .active ==
                                                                    true ||
                                                                  _vm.isAdmin
                                                                    ? _c(
                                                                        VListItem,
                                                                        {
                                                                          style:
                                                                            _vm.selectedLabInfo &&
                                                                            _vm
                                                                              .selectedLabInfo
                                                                              .labId ==
                                                                              groupedLab
                                                                              ? "color: #2379cf"
                                                                              : "",
                                                                          attrs:
                                                                            {
                                                                              link: "",
                                                                            },
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.getSelectedLabInfo(
                                                                                  lab,
                                                                                  groupedLab
                                                                                )
                                                                              },
                                                                          },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            VListItemTitle,
                                                                            {
                                                                              key: _vm.updateLabsList,
                                                                              staticStyle:
                                                                                {
                                                                                  "margin-left":
                                                                                    "20px",
                                                                                },
                                                                              style:
                                                                                _vm.selectedLabInfo &&
                                                                                _vm
                                                                                  .selectedLabInfo
                                                                                  .labId ==
                                                                                  groupedLab
                                                                                  ? "color: #2379cf"
                                                                                  : "",
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "li",
                                                                                {
                                                                                  style:
                                                                                    _vm
                                                                                      .AlllabsList[
                                                                                      lab
                                                                                        .groupName
                                                                                    ][
                                                                                      groupedLab
                                                                                    ]
                                                                                      .active
                                                                                      ? ""
                                                                                      : "opacity: 0.4;",
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "\n                                                        " +
                                                                                      _vm._s(
                                                                                        _vm
                                                                                          .AlllabsList[
                                                                                          lab
                                                                                            .groupName
                                                                                        ][
                                                                                          groupedLab
                                                                                        ]
                                                                                          .labName
                                                                                      ) +
                                                                                      "\n                                                        "
                                                                                  ),
                                                                                  _vm
                                                                                    .AlllabsList[
                                                                                    lab
                                                                                      .groupName
                                                                                  ][
                                                                                    groupedLab
                                                                                  ]
                                                                                    .price
                                                                                    ? _c(
                                                                                        VIcon,
                                                                                        {
                                                                                          attrs:
                                                                                            {
                                                                                              small:
                                                                                                "",
                                                                                            },
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            " mdi-lock-outline\n                                                        "
                                                                                          ),
                                                                                        ]
                                                                                      )
                                                                                    : _vm._e(),
                                                                                  _vm
                                                                                    .AlllabsList[
                                                                                    lab
                                                                                      .groupName
                                                                                  ][
                                                                                    groupedLab
                                                                                  ]
                                                                                    .labStatus &&
                                                                                  _vm
                                                                                    .AlllabsList[
                                                                                    lab
                                                                                      .groupName
                                                                                  ][
                                                                                    groupedLab
                                                                                  ]
                                                                                    .labStatus
                                                                                    .status ==
                                                                                    "completed"
                                                                                    ? _c(
                                                                                        VChip,
                                                                                        {
                                                                                          staticStyle:
                                                                                            {
                                                                                              "margin-left":
                                                                                                "5px",
                                                                                            },
                                                                                          attrs:
                                                                                            {
                                                                                              color:
                                                                                                "green",
                                                                                              outlined:
                                                                                                "",
                                                                                              "x-small":
                                                                                                "",
                                                                                            },
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            "완료\n                                                        "
                                                                                          ),
                                                                                        ]
                                                                                      )
                                                                                    : _vm._e(),
                                                                                  _vm
                                                                                    .AlllabsList[
                                                                                    lab
                                                                                      .groupName
                                                                                  ][
                                                                                    groupedLab
                                                                                  ]
                                                                                    .labStatus &&
                                                                                  _vm
                                                                                    .AlllabsList[
                                                                                    lab
                                                                                      .groupName
                                                                                  ][
                                                                                    groupedLab
                                                                                  ]
                                                                                    .labStatus
                                                                                    .status ==
                                                                                    "started"
                                                                                    ? _c(
                                                                                        VChip,
                                                                                        {
                                                                                          staticStyle:
                                                                                            {
                                                                                              "margin-left":
                                                                                                "5px",
                                                                                            },
                                                                                          attrs:
                                                                                            {
                                                                                              color:
                                                                                                "red",
                                                                                              outlined:
                                                                                                "",
                                                                                              "x-small":
                                                                                                "",
                                                                                            },
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            "진행중\n                                                        "
                                                                                          ),
                                                                                        ]
                                                                                      )
                                                                                    : _vm._e(),
                                                                                  _vm
                                                                                    .AlllabsList[
                                                                                    lab
                                                                                      .groupName
                                                                                  ][
                                                                                    groupedLab
                                                                                  ]
                                                                                    .passMessage
                                                                                    ? _c(
                                                                                        VChip,
                                                                                        {
                                                                                          staticStyle:
                                                                                            {
                                                                                              "margin-left":
                                                                                                "5px",
                                                                                            },
                                                                                          attrs:
                                                                                            {
                                                                                              color:
                                                                                                _vm
                                                                                                  .AlllabsList[
                                                                                                  lab
                                                                                                    .groupName
                                                                                                ][
                                                                                                  groupedLab
                                                                                                ]
                                                                                                  .passMessage ==
                                                                                                "접속"
                                                                                                  ? "blue darken-1"
                                                                                                  : "green",
                                                                                              outlined:
                                                                                                "",
                                                                                              "x-small":
                                                                                                "",
                                                                                            },
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            "\n                                                            " +
                                                                                              _vm._s(
                                                                                                _vm
                                                                                                  .AlllabsList[
                                                                                                  lab
                                                                                                    .groupName
                                                                                                ][
                                                                                                  groupedLab
                                                                                                ]
                                                                                                  .passMessage
                                                                                              ) +
                                                                                              "\n                                                        "
                                                                                          ),
                                                                                        ]
                                                                                      )
                                                                                    : _vm._e(),
                                                                                ],
                                                                                1
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ],
                                                                        1
                                                                      )
                                                                    : _vm._e(),
                                                                ],
                                                                1
                                                              )
                                                            : _c(
                                                                VListItem,
                                                                {
                                                                  attrs: {
                                                                    link: "",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    VListItemTitle,
                                                                    {
                                                                      key: _vm.updateLabsList,
                                                                      staticStyle:
                                                                        {
                                                                          "margin-left":
                                                                            "20px",
                                                                          "white-space":
                                                                            "nowrap",
                                                                        },
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.getSelectedLabInfo(
                                                                              lab,
                                                                              groupedLab
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _c("li", [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            groupedLab
                                                                          )
                                                                        ),
                                                                      ]),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                        ],
                                                        1
                                                      )
                                                    }
                                                  ),
                                                  0
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    0
                                  ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                VCol,
                {
                  staticStyle: { "padding-bottom": "100px" },
                  attrs: { cols: _vm.isMobile ? 12 : 9 },
                },
                [
                  _vm.editModeController == "selectedLabInfo"
                    ? _c(
                        "div",
                        {
                          key: _vm.updateCard,
                          staticStyle: {
                            "margin-top": "30px",
                            "margin-left": "15px",
                            "margin-right": "10px",
                          },
                        },
                        [
                          _vm.isLoadingLabInfo
                            ? _c(VProgressCircular, {
                                staticStyle: {
                                  padding: "20px",
                                  margin: "15px",
                                },
                                attrs: { indeterminate: "", color: "primary" },
                              })
                            : _c("div", [
                                _vm.selectedLabInfo
                                  ? _c(
                                      "div",
                                      { staticStyle: { "text-align": "left" } },
                                      [
                                        _c(
                                          VChip,
                                          {
                                            staticStyle: {
                                              "margin-top": "-30px",
                                              color: "white",
                                              "font-weight": "300",
                                            },
                                            attrs: {
                                              color: "#9E9E9E",
                                              small: "",
                                            },
                                          },
                                          [
                                            _c("b", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.selectedLabInfo.labId
                                                )
                                              ),
                                            ]),
                                          ]
                                        ),
                                        _c(
                                          VChip,
                                          {
                                            staticStyle: {
                                              "margin-top": "-30px",
                                              "margin-left": "5px",
                                              color: "white",
                                              "font-weight": "300",
                                            },
                                            attrs: {
                                              color: "#9E9E9E",
                                              small: "",
                                            },
                                          },
                                          [
                                            _c("b", [
                                              _vm._v(
                                                _vm._s(_vm.selectedLabInfo.tool)
                                              ),
                                            ]),
                                          ]
                                        ),
                                        _vm.selectedLabInfo.labStatus &&
                                        _vm.selectedLabInfo.labStatus.status ==
                                          "completed"
                                          ? _c(
                                              VChip,
                                              {
                                                staticStyle: {
                                                  "margin-top": "-30px",
                                                  "margin-left": "5px",
                                                },
                                                attrs: {
                                                  color: "green",
                                                  outlined: "",
                                                  small: "",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "완료\n                            "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm.selectedLabInfo.labStatus &&
                                        _vm.selectedLabInfo.labStatus.status ==
                                          "started"
                                          ? _c(
                                              VChip,
                                              {
                                                staticStyle: {
                                                  "margin-top": "-30px",
                                                  "margin-left": "5px",
                                                },
                                                attrs: {
                                                  color: "red",
                                                  outlined: "",
                                                  small: "",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "진행중\n                            "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm.selectedLabInfo.passMessage
                                          ? _c(
                                              VChip,
                                              {
                                                staticStyle: {
                                                  "margin-top": "-30px",
                                                  "margin-left": "5px",
                                                },
                                                attrs: {
                                                  color:
                                                    _vm.selectedLabInfo
                                                      .passMessage == "접속"
                                                      ? "blue darken-1"
                                                      : "green",
                                                  outlined: "",
                                                  small: "",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                " +
                                                    _vm._s(
                                                      _vm.selectedLabInfo
                                                        .passMessage
                                                    ) +
                                                    "\n                            "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm.AlllabsList[
                                          _vm.selectedLabInfo.groupName
                                        ] &&
                                        _vm.AlllabsList[
                                          _vm.selectedLabInfo.groupName
                                        ][_vm.selectedLabInfo.labId] &&
                                        _vm.AlllabsList[
                                          _vm.selectedLabInfo.groupName
                                        ][_vm.selectedLabInfo.labId].active
                                          ? _c(
                                              VIcon,
                                              {
                                                staticStyle: {
                                                  "margin-left": "5px",
                                                  "margin-top": "-30px",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " mdi-eye-off-outline\n                            "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              float: "right",
                                              "margin-top": "-15px",
                                            },
                                          },
                                          [
                                            _vm.selectedLabInfo.groupName ==
                                            "Ops"
                                              ? _c(
                                                  VIcon,
                                                  {
                                                    staticStyle: {
                                                      "margin-right": "10px",
                                                    },
                                                    attrs: { size: "20" },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.openGitpodIde()
                                                      },
                                                    },
                                                  },
                                                  [_vm._v("mdi-git")]
                                                )
                                              : _vm._e(),
                                            _vm.isAdmin
                                              ? _c(
                                                  VIcon,
                                                  {
                                                    staticStyle: {
                                                      "margin-right": "10px",
                                                    },
                                                    attrs: { size: "20" },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.openDeleteLabDialog()
                                                      },
                                                    },
                                                  },
                                                  [_vm._v("mdi-archive")]
                                                )
                                              : _vm._e(),
                                            _vm.isAdmin
                                              ? _c(
                                                  VIcon,
                                                  {
                                                    staticStyle: {
                                                      "margin-right": "5px",
                                                    },
                                                    attrs: { size: "20" },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.openEditLabInfo(
                                                          _vm.selectedLabInfo
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v("mdi-pencil")]
                                                )
                                              : _vm._e(),
                                            _c(
                                              VBtn,
                                              {
                                                staticStyle: {
                                                  "font-weight": "700",
                                                  padding: "10px",
                                                },
                                                attrs: { color: "primary" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.loginToUseLab()
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  VIcon,
                                                  {
                                                    staticStyle: {
                                                      "margin-right": "3px",
                                                    },
                                                    attrs: { left: "" },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                        mdi-login\n                                    "
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(
                                                  "\n                                    INTO THE LAB\n                                "
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(VCol, [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "margin-left": "-12px",
                                                "margin-bottom": "-10px",
                                                "margin-top": "-20px",
                                                display: "flex",
                                              },
                                            },
                                            [
                                              _c("h2", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.selectedLabInfo.labName
                                                  )
                                                ),
                                              ]),
                                              _c(
                                                VTooltip,
                                                {
                                                  attrs: { right: "" },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function ({ on }) {
                                                          return [
                                                            _vm.selectedLabInfo
                                                              .video
                                                              ? _c(
                                                                  VIcon,
                                                                  _vm._g(
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          "margin-left":
                                                                            "5px",
                                                                        },
                                                                      attrs: {
                                                                        color:
                                                                          "grey",
                                                                      },
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.openLabVideoDialog()
                                                                          },
                                                                      },
                                                                    },
                                                                    on
                                                                  ),
                                                                  [
                                                                    _vm._v(
                                                                      "mdi-youtube"
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    false,
                                                    3643596269
                                                  ),
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v("참고영상 시청"),
                                                  ]),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c("div", {
                                  staticStyle: { margin: "5px 0 10px 0" },
                                }),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "text-align": "left",
                                      "margin-top": "0px",
                                      color: "rgba(0, 0, 0, 0.6)",
                                    },
                                  },
                                  [
                                    _vm.selectedLabInfo &&
                                    _vm.selectedLabInfo.labScenario
                                      ? _c("h4", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.selectedLabInfo.labScenario
                                            )
                                          ),
                                        ])
                                      : _vm._e(),
                                    _vm.selectedLabInfo &&
                                    _vm.selectedLabInfo.labTime
                                      ? _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              margin: "10px 0 -30px 0",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                " +
                                                _vm._s(
                                                  _vm.selectedLabInfo.labTime
                                                ) +
                                                "분 이내 완료\n                            "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm.selectedLabInfo.instructionMd
                                      ? _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              "font-weight": "700",
                                              "margin-top": "40px",
                                            },
                                          },
                                          [
                                            _c(
                                              "b",
                                              {
                                                staticStyle: {
                                                  "font-size": "17px",
                                                },
                                              },
                                              [_vm._v("Instruction ")]
                                            ),
                                            _c(VDivider),
                                            _c(
                                              "div",
                                              [
                                                _c("vue-markdown", {
                                                  staticClass: "markdown-body",
                                                  attrs: {
                                                    source:
                                                      _vm.selectedLabInfo
                                                        .instructionMd,
                                                  },
                                                }),
                                                _c(VDivider),
                                                _c(
                                                  VList,
                                                  { attrs: { dense: "" } },
                                                  [
                                                    _c(VListItemTitle, [
                                                      _c("b", [
                                                        _vm._v("CheckPoints"),
                                                      ]),
                                                    ]),
                                                    _vm._l(
                                                      _vm.selectedLabInfo
                                                        .checkPoints,
                                                      function (item, i) {
                                                        return _c(
                                                          VListItem,
                                                          { key: item.text },
                                                          [
                                                            _c(
                                                              VListItemContent,
                                                              [
                                                                _c(
                                                                  VListItemTitle,
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        i + 1
                                                                      ) +
                                                                        ". " +
                                                                        _vm._s(
                                                                          item.text
                                                                        )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      }
                                                    ),
                                                  ],
                                                  2
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _c(VDivider),
                                  ],
                                  1
                                ),
                              ]),
                        ],
                        1
                      )
                    : _vm.editModeController == "openClassInfoCard"
                    ? _c(
                        "div",
                        {
                          staticStyle: {
                            margin: "15px",
                            "margin-right": "-5px",
                          },
                        },
                        [
                          _vm.classInfo && _vm.courseId
                            ? _c(
                                "div",
                                { staticStyle: { margin: "15px" } },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        "margin-left": "10px",
                                        float: "right",
                                      },
                                    },
                                    [
                                      !_vm.editClassIntroduction && _vm.isAdmin
                                        ? _c(
                                            VIcon,
                                            {
                                              on: {
                                                click: _vm.openClassInfoDialog,
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "mdi-information-outline\n                            "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                  _c("h1", [_vm._v("강의 소개")]),
                                  _vm.classInfo.price && !_vm.isPaidClass
                                    ? _c(
                                        "div",
                                        [
                                          _c(
                                            VIcon,
                                            { attrs: { small: "" } },
                                            [_vm._v(" mdi-lock-outline")]
                                          ),
                                          _vm._v(
                                            "\n                            클래스 구매\n                        "
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "div",
                                    { staticStyle: { display: "flex" } },
                                    [
                                      _c("h4", [_vm._v("Introduction")]),
                                      !_vm.editClassIntroduction && _vm.isAdmin
                                        ? _c(
                                            VIcon,
                                            {
                                              staticStyle: {
                                                "margin-left": "3px",
                                              },
                                              attrs: { small: "", dense: "" },
                                              on: {
                                                click: function ($event) {
                                                  _vm.editClassIntroduction = true
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                mdi-pencil\n                            "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                  _vm.classIntroduction
                                    ? _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            "margin-top": "5px",
                                            padding: "5px",
                                            "margin-bottom": "5px",
                                          },
                                        },
                                        [
                                          !_vm.editClassIntroduction
                                            ? _c("vue-markdown", {
                                                staticClass: "markdown-body",
                                                style: {
                                                  textDecoration:
                                                    "none !important",
                                                },
                                                attrs: {
                                                  source: _vm.classIntroduction,
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  !_vm.editClassIntroduction
                                    ? _c(VDivider)
                                    : _vm._e(),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        float: "right",
                                        "margin-top": "-20px",
                                        "margin-right": "10px",
                                      },
                                    },
                                    [
                                      _vm.editClassIntroduction
                                        ? _c(
                                            VBtn,
                                            {
                                              staticStyle: {
                                                "font-size": "700",
                                              },
                                              attrs: { small: "", text: "" },
                                              on: {
                                                click: function ($event) {
                                                  _vm.editClassIntroduction = false
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "Cancel\n                            "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm.editClassIntroduction
                                        ? _c(
                                            VBtn,
                                            {
                                              staticStyle: {
                                                "font-size": "700",
                                              },
                                              attrs: {
                                                small: "",
                                                text: "",
                                                color: "primary",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.editIntroduction(
                                                    _vm.classIntroduction
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "Save\n                            "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        "margin-top": "5px",
                                        padding: "5px",
                                        "margin-bottom": "5px",
                                      },
                                    },
                                    [
                                      _vm.editClassIntroduction
                                        ? _c(
                                            VCol,
                                            [
                                              _c("vue-simplemde", {
                                                ref: "markdownEditor",
                                                model: {
                                                  value: _vm.classIntroduction,
                                                  callback: function ($$v) {
                                                    _vm.classIntroduction = $$v
                                                  },
                                                  expression:
                                                    "classIntroduction",
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      !_vm.editClassIntroduction && _vm.isOwner
                                        ? _c(VCheckbox, {
                                            staticStyle: {
                                              display: "table-row",
                                            },
                                            attrs: {
                                              label:
                                                "이 유형의 모든 클래스에 반영",
                                              color: "primary",
                                            },
                                            model: {
                                              value: _vm.editCourseMandatory,
                                              callback: function ($$v) {
                                                _vm.editCourseMandatory = $$v
                                              },
                                              expression: "editCourseMandatory",
                                            },
                                          })
                                        : _vm._e(),
                                      _c(
                                        VRow,
                                        { attrs: { justify: "center" } },
                                        [
                                          _c(
                                            VDialog,
                                            {
                                              attrs: { width: "600px" },
                                              model: {
                                                value: _vm.classInfoDialog,
                                                callback: function ($$v) {
                                                  _vm.classInfoDialog = $$v
                                                },
                                                expression: "classInfoDialog",
                                              },
                                            },
                                            [
                                              _c(
                                                VCard,
                                                [
                                                  _c(
                                                    VBtn,
                                                    {
                                                      staticStyle: {
                                                        float: "right",
                                                      },
                                                      attrs: { icon: "" },
                                                      nativeOn: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          _vm.classInfoDialog = false
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        VIcon,
                                                        {
                                                          attrs: {
                                                            color:
                                                              "grey lighten-1",
                                                          },
                                                        },
                                                        [_vm._v("mdi-close")]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        padding:
                                                          "20px 0 0 25px",
                                                      },
                                                    },
                                                    [
                                                      _vm.classInfo.classId
                                                        ? _c(
                                                            "div",
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticStyle: {
                                                                    "font-weight":
                                                                      "700",
                                                                    "font-size":
                                                                      "16px",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Class ID"
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                VTextField,
                                                                {
                                                                  staticStyle: {
                                                                    "margin-right":
                                                                      "25px",
                                                                  },
                                                                  attrs: {
                                                                    id: "copy-class-id",
                                                                    label:
                                                                      "ClassId",
                                                                    value:
                                                                      _vm
                                                                        .classInfo
                                                                        .classId,
                                                                    "append-icon":
                                                                      "mdi-clipboard-text",
                                                                    solo: "",
                                                                    readonly:
                                                                      "",
                                                                  },
                                                                  on: {
                                                                    "click:append":
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.copytoClipBoard(
                                                                          "classId"
                                                                        )
                                                                      },
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                      _vm.classInfo
                                                        .connectionKey
                                                        ? _c(
                                                            "div",
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticStyle: {
                                                                    "font-weight":
                                                                      "700",
                                                                    "font-size":
                                                                      "16px",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Connection Key"
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                VTextField,
                                                                {
                                                                  staticStyle: {
                                                                    "margin-right":
                                                                      "25px",
                                                                  },
                                                                  attrs: {
                                                                    id: "copy-connection-key",
                                                                    label:
                                                                      "ConnectionKey",
                                                                    value:
                                                                      _vm
                                                                        .classInfo
                                                                        .connectionKey,
                                                                    "append-icon":
                                                                      "mdi-clipboard-text",
                                                                    solo: "",
                                                                    readonly:
                                                                      "",
                                                                  },
                                                                  on: {
                                                                    "click:append":
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.copytoClipBoard(
                                                                          "connectionKey"
                                                                        )
                                                                      },
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  ),
                                                  !_vm.classInfo.archive
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticStyle: {
                                                            "text-align-last":
                                                              "center",
                                                          },
                                                        },
                                                        [
                                                          _c(VDivider, {
                                                            staticStyle: {
                                                              margin: "20px",
                                                            },
                                                          }),
                                                          _c(
                                                            VBtn,
                                                            {
                                                              staticStyle: {
                                                                "margin-bottom":
                                                                  "10px",
                                                                "margin-top":
                                                                  "-10px",
                                                              },
                                                              attrs: {
                                                                small: "",
                                                                color: "error",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    _vm.openMoveDialog = true
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "강의 종료"
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      )
                    : _vm.editModeController == "editMode"
                    ? _c("div", { staticStyle: { margin: "15px" } }, [
                        _c(
                          "div",
                          [
                            _c(VTextField, {
                              staticStyle: { "max-width": "300px" },
                              attrs: {
                                filled: "",
                                rounded: "",
                                dense: "",
                                label: "lab-Id *",
                                disabled: "",
                              },
                              model: {
                                value: _vm.editLabInfo.labId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.editLabInfo, "labId", $$v)
                                },
                                expression: "editLabInfo.labId",
                              },
                            }),
                            _c(VTextField, {
                              staticStyle: { "max-width": "400px" },
                              attrs: {
                                outlined: "",
                                label: "lab-Name *",
                                rules: _vm.nameRules,
                              },
                              model: {
                                value: _vm.editLabInfo.labName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.editLabInfo, "labName", $$v)
                                },
                                expression: "editLabInfo.labName",
                              },
                            }),
                            _c(VSwitch, {
                              attrs: { label: "Active", color: "primary" },
                              model: {
                                value: _vm.editLabInfo.active,
                                callback: function ($$v) {
                                  _vm.$set(_vm.editLabInfo, "active", $$v)
                                },
                                expression: "editLabInfo.active",
                              },
                            }),
                            _c(VSwitch, {
                              attrs: {
                                "input-value": _vm.editLabInfo.certificate,
                                label: "수료 기준 설정",
                                color: "primary",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.onChangedMandatory(_vm.editLabInfo)
                                },
                              },
                            }),
                            _vm.editLabInfo.mandatory &&
                            _vm.editLabInfo.certificate
                              ? _c(
                                  VRow,
                                  {
                                    staticStyle: {
                                      "max-width": "360px",
                                      "flex-flow": "row",
                                      "margin-left": "1px",
                                    },
                                  },
                                  [
                                    _c(VAutocomplete, {
                                      attrs: {
                                        label: "Mandatory passed",
                                        "auto-select-first": "",
                                        dense: "",
                                        solo: "",
                                        items: _vm.getMandatoryMenuLists,
                                        "item-text": "text",
                                        "item-value": "value",
                                      },
                                      model: {
                                        value:
                                          _vm.editLabInfo.mandatory.checkPoint,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.editLabInfo.mandatory,
                                            "checkPoint",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "editLabInfo.mandatory.checkPoint",
                                      },
                                    }),
                                    _c(VCheckbox, {
                                      attrs: { dense: "", label: "출석 여부" },
                                      model: {
                                        value: _vm.editLabInfo.mandatory.attend,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.editLabInfo.mandatory,
                                            "attend",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "editLabInfo.mandatory.attend",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(VTextarea, {
                              staticStyle: { width: "100%" },
                              attrs: {
                                outlined: "",
                                label: "lab-Scenario",
                                height: "100",
                              },
                              model: {
                                value: _vm.editLabInfo.labScenario,
                                callback: function ($$v) {
                                  _vm.$set(_vm.editLabInfo, "labScenario", $$v)
                                },
                                expression: "editLabInfo.labScenario",
                              },
                            }),
                            _c(VSelect, {
                              staticStyle: { "max-width": "400px" },
                              attrs: {
                                items: _vm.groupNameList,
                                label: "group *",
                                required: "",
                              },
                              model: {
                                value: _vm.editLabInfo.groupName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.editLabInfo, "groupName", $$v)
                                },
                                expression: "editLabInfo.groupName",
                              },
                            }),
                            _c(VSelect, {
                              staticStyle: { "max-width": "400px" },
                              attrs: {
                                items: [
                                  "event-storming",
                                  "ide",
                                  "kuber-ez",
                                  "url",
                                  "business-model-canvas",
                                  "quiz",
                                ],
                                label: "tool *",
                                required: "",
                              },
                              model: {
                                value: _vm.editLabInfo.tool,
                                callback: function ($$v) {
                                  _vm.$set(_vm.editLabInfo, "tool", $$v)
                                },
                                expression: "editLabInfo.tool",
                              },
                            }),
                            _vm.editLabInfo.tool == "event-storming"
                              ? _c(VTextField, {
                                  attrs: {
                                    label: "모델 URL 추가",
                                    color: "primary",
                                  },
                                  model: {
                                    value: _vm.editLabInfo.modelUrl,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.editLabInfo, "modelUrl", $$v)
                                    },
                                    expression: "editLabInfo.modelUrl",
                                  },
                                })
                              : _vm._e(),
                            _vm.editLabInfo.tool == "ide"
                              ? _c(VSwitch, {
                                  attrs: {
                                    label: "워크스페이스 생성",
                                    color: "primary",
                                  },
                                  model: {
                                    value: _vm.editLabInfo.addWorkSpace,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.editLabInfo,
                                        "addWorkSpace",
                                        $$v
                                      )
                                    },
                                    expression: "editLabInfo.addWorkSpace",
                                  },
                                })
                              : _vm._e(),
                            _c(
                              VRow,
                              { staticStyle: { height: "50px" } },
                              [
                                _c(VTextField, {
                                  staticClass: "input-field",
                                  staticStyle: {
                                    "margin-left": "10px",
                                    "margin-right": "10px",
                                    "max-width": "200px",
                                  },
                                  attrs: {
                                    outlined: "",
                                    label: "lab-Price",
                                    disabled: _vm.connectionKeyLength > 0,
                                    type: "number",
                                  },
                                  model: {
                                    value: _vm.editLabInfo.price,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.editLabInfo, "price", $$v)
                                    },
                                    expression: "editLabInfo.price",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "margin-top": "20px",
                                  float: "right",
                                },
                              },
                              [
                                _c(
                                  VBtn,
                                  {
                                    staticStyle: { "font-weight": "700" },
                                    attrs: { text: "" },
                                    on: {
                                      click: function ($event) {
                                        _vm.editModeController =
                                          "selectedLabInfo"
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "취소\n                                "
                                    ),
                                  ]
                                ),
                                _c(
                                  VBtn,
                                  {
                                    staticStyle: {
                                      "margin-right": "0px",
                                      "font-weight": "700",
                                      color: "#1E88E5",
                                    },
                                    attrs: {
                                      text: "",
                                      disabled: !_vm.editLabInfo.labName,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.submitEditLabInfo(
                                          _vm.editLabInfo
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "수정\n                                "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ])
                    : _vm.selectedGroupInfo &&
                      _vm.editModeController == "openGroupCard"
                    ? _c(
                        "div",
                        [
                          _c(
                            VCol,
                            [
                              _c(
                                "div",
                                { staticStyle: { display: "flex" } },
                                [
                                  _c("h1", [
                                    _vm._v(
                                      _vm._s(_vm.selectedGroupInfo.groupName)
                                    ),
                                  ]),
                                  _vm.selectedGroupInfo.groupName !=
                                    "uncategorized" && _vm.isAdmin
                                    ? _c(
                                        VIcon,
                                        {
                                          staticStyle: { "margin-left": "5px" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.openEditGroupCard(
                                                _vm.selectedGroupInfo
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("mdi-pencil")]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _vm.selectedGroupInfo.goal
                                ? _c("h3", [
                                    _vm._v(_vm._s(_vm.selectedGroupInfo.goal)),
                                  ])
                                : _vm._e(),
                              _c(VSelect, {
                                attrs: {
                                  items: _vm.setSelectedGroupLabsNameList,
                                  attach: "",
                                  chips: "",
                                  label: "Labs",
                                  multiple: "",
                                  readonly: "",
                                },
                                model: {
                                  value: _vm.selectedGroupInfo.labNameList,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.selectedGroupInfo,
                                      "labNameList",
                                      $$v
                                    )
                                  },
                                  expression: "selectedGroupInfo.labNameList",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm.editModeController == "EditModeforGroupCard"
                    ? _c(
                        "div",
                        [
                          _c(
                            VCol,
                            [
                              _c(VTextField, {
                                staticStyle: { width: "300px" },
                                attrs: {
                                  filled: "",
                                  rounded: "",
                                  dense: "",
                                  label: "groupName *",
                                  disabled:
                                    _vm.setEditGroupInfo.groupName ==
                                    "uncategorized"
                                      ? true
                                      : false,
                                  rules: _vm.vaildateRules,
                                },
                                model: {
                                  value: _vm.setEditGroupInfo.groupName,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.setEditGroupInfo,
                                      "groupName",
                                      $$v
                                    )
                                  },
                                  expression: "setEditGroupInfo.groupName",
                                },
                              }),
                              _c(VSelect, {
                                attrs: {
                                  disabled:
                                    _vm.setEditGroupInfo.groupName ==
                                    "uncategorized"
                                      ? true
                                      : false,
                                  items: _vm.setSelectedGroupLabsNameList,
                                  attach: "",
                                  chips: "",
                                  label:
                                    "Unselected lab will be move uncategorized Group",
                                  multiple: "",
                                },
                                model: {
                                  value: _vm.setEditGroupInfo.labNameList,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.setEditGroupInfo,
                                      "labNameList",
                                      $$v
                                    )
                                  },
                                  expression: "setEditGroupInfo.labNameList",
                                },
                              }),
                              _c(VTextarea, {
                                attrs: {
                                  outlined: "",
                                  label: "lab-Scenario",
                                  height: "200",
                                },
                                model: {
                                  value: _vm.setEditGroupInfo.goal,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.setEditGroupInfo, "goal", $$v)
                                  },
                                  expression: "setEditGroupInfo.goal",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                float: "right",
                                "font-weight": "700",
                                "margin-top": "15px",
                                "margin-right": "15px",
                                "margin-bottom": "10px",
                              },
                            },
                            [
                              _c(
                                VBtn,
                                {
                                  attrs: { text: "" },
                                  on: {
                                    click: function ($event) {
                                      _vm.editModeController = "openGroupCard"
                                    },
                                  },
                                },
                                [_vm._v("cancel")]
                              ),
                              _c(
                                VBtn,
                                {
                                  staticStyle: {
                                    color: "#1E88E5",
                                    "font-weight": "700",
                                  },
                                  attrs: {
                                    text: "",
                                    disabled: _vm.vaildateCheckforEditGroupName,
                                    type: "submit",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.editGroup(_vm.setEditGroupInfo)
                                    },
                                  },
                                },
                                [_vm._v("수정\n                        ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm.editModeController == "inputLabinfo"
                    ? _c(
                        "div",
                        {
                          staticStyle: {
                            margin: "15px",
                            "margin-right": "-5px",
                          },
                        },
                        [
                          _c(
                            VTabs,
                            {
                              model: {
                                value: _vm.tab,
                                callback: function ($$v) {
                                  _vm.tab = $$v
                                },
                                expression: "tab",
                              },
                            },
                            _vm._l(_vm.items, function (item) {
                              return _c(VTab, { key: item }, [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(item) +
                                    "\n                        "
                                ),
                              ])
                            }),
                            1
                          ),
                          _c(
                            VTabsItems,
                            {
                              model: {
                                value: _vm.tab,
                                callback: function ($$v) {
                                  _vm.tab = $$v
                                },
                                expression: "tab",
                              },
                            },
                            _vm._l(_vm.items, function (item) {
                              return _c(VTabItem, { key: item }, [
                                item == "New Group"
                                  ? _c(
                                      "div",
                                      {
                                        staticStyle: { "margin-right": "10px" },
                                      },
                                      [
                                        _c(
                                          VCol,
                                          [
                                            _c(VTextField, {
                                              staticStyle: { width: "300px" },
                                              attrs: {
                                                filled: "",
                                                rounded: "",
                                                dense: "",
                                                label: "groupName *",
                                                rules: _vm.vaildateRules,
                                              },
                                              model: {
                                                value: _vm.newGroup.groupName,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.newGroup,
                                                    "groupName",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "newGroup.groupName",
                                              },
                                            }),
                                            _c(VSelect, {
                                              attrs: {
                                                items:
                                                  _vm.uncategorizedGroupLabsList,
                                                attach: "",
                                                chips: "",
                                                label: "Select Lab",
                                                multiple: "",
                                              },
                                              model: {
                                                value: _vm.newGroup.labsList,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.newGroup,
                                                    "labsList",
                                                    $$v
                                                  )
                                                },
                                                expression: "newGroup.labsList",
                                              },
                                            }),
                                            _c(VTextarea, {
                                              attrs: {
                                                outlined: "",
                                                label: "lab-Scenario",
                                                height: "200",
                                              },
                                              model: {
                                                value: _vm.newGroup.goal,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.newGroup,
                                                    "goal",
                                                    $$v
                                                  )
                                                },
                                                expression: "newGroup.goal",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          VBtn,
                                          {
                                            staticStyle: {
                                              float: "right",
                                              "font-weight": "700",
                                              "margin-top": "15px",
                                              "margin-right": "15px",
                                              color: "#1E88E5",
                                            },
                                            attrs: {
                                              text: "",
                                              disabled:
                                                _vm.vaildateCheckforGroupName,
                                              type: "submit",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.addNewGroup(
                                                  _vm.newGroup
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "생성\n                                "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : item == "New Lab"
                                  ? _c(
                                      "div",
                                      {
                                        staticStyle: { "margin-right": "10px" },
                                      },
                                      [
                                        _c(
                                          VCol,
                                          [
                                            _c(VTextField, {
                                              staticStyle: { width: "300px" },
                                              attrs: {
                                                rules: _vm.vaildateRules,
                                                filled: "",
                                                rounded: "",
                                                dense: "",
                                                label: "lab-Id *",
                                              },
                                              model: {
                                                value: _vm.newLab.LabId,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.newLab,
                                                    "LabId",
                                                    $$v
                                                  )
                                                },
                                                expression: "newLab.LabId",
                                              },
                                            }),
                                            _c(VTextField, {
                                              staticStyle: { width: "400px" },
                                              attrs: {
                                                rules: _vm.nameRules,
                                                outlined: "",
                                                label: "lab-Name *",
                                              },
                                              model: {
                                                value: _vm.newLab.LabName,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.newLab,
                                                    "LabName",
                                                    $$v
                                                  )
                                                },
                                                expression: "newLab.LabName",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          VCol,
                                          [
                                            _c(VTextarea, {
                                              attrs: {
                                                outlined: "",
                                                label: "lab-Scenario",
                                                height: "200",
                                              },
                                              model: {
                                                value: _vm.newLab.LabScenario,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.newLab,
                                                    "LabScenario",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "newLab.LabScenario",
                                              },
                                            }),
                                            _c(VSelect, {
                                              key: _vm.keyforGroupNameList,
                                              staticStyle: { width: "400px" },
                                              attrs: {
                                                rules: _vm.nameRules,
                                                items: _vm.groupNameList,
                                                label: "group *",
                                                required: "",
                                              },
                                              model: {
                                                value: _vm.newLab.groupName,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.newLab,
                                                    "groupName",
                                                    $$v
                                                  )
                                                },
                                                expression: "newLab.groupName",
                                              },
                                            }),
                                            _c(VSelect, {
                                              staticStyle: { width: "400px" },
                                              attrs: {
                                                rules: _vm.nameRules,
                                                items: [
                                                  "event-storming",
                                                  "ide",
                                                  "kuber-ez",
                                                  "url",
                                                  "business-model-canvas",
                                                  "quiz",
                                                ],
                                                label: "tool *",
                                                required: "",
                                              },
                                              model: {
                                                value: _vm.newLab.Labtool,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.newLab,
                                                    "Labtool",
                                                    $$v
                                                  )
                                                },
                                                expression: "newLab.Labtool",
                                              },
                                            }),
                                            _vm.newLab.Labtool ==
                                            "event-storming"
                                              ? _c(VTextField, {
                                                  attrs: {
                                                    label: "모델 URL 추가",
                                                    color: "primary",
                                                  },
                                                  model: {
                                                    value: _vm.newLab.modelUrl,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.newLab,
                                                        "modelUrl",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "newLab.modelUrl",
                                                  },
                                                })
                                              : _vm._e(),
                                            _vm.newLab.Labtool == "ide"
                                              ? _c(VSwitch, {
                                                  attrs: {
                                                    label: "워크스페이스 생성",
                                                    color: "primary",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.newLab.addWorkSpace,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.newLab,
                                                        "addWorkSpace",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "newLab.addWorkSpace",
                                                  },
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                        _c(VTextField, {
                                          staticClass: "input-field",
                                          staticStyle: {
                                            width: "200px",
                                            "margin-left": "10px",
                                          },
                                          attrs: {
                                            outlined: "",
                                            label: "lab-Price",
                                            disabled:
                                              _vm.connectionKeyLength > 0,
                                            type: "number",
                                          },
                                          model: {
                                            value: _vm.newLab.LabPrice,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.newLab,
                                                "LabPrice",
                                                $$v
                                              )
                                            },
                                            expression: "newLab.LabPrice",
                                          },
                                        }),
                                        _c(
                                          VBtn,
                                          {
                                            staticStyle: {
                                              float: "right",
                                              "font-weight": "700",
                                              "margin-top": "-20px",
                                              "margin-right": "15px",
                                              color: "#1E88E5",
                                            },
                                            attrs: {
                                              text: "",
                                              type: "submit",
                                              disabled:
                                                _vm.vaildateCheckforLabId,
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.addNewLab(_vm.newLab)
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "생성\n                                "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          display: "flex",
                                          margin: "0px 15px 15px 0px",
                                          width: "-webkit-fill-available",
                                        },
                                      },
                                      [
                                        !_vm.isMobile
                                          ? _c(
                                              VNavigationDrawer,
                                              {
                                                attrs: {
                                                  height: "700",
                                                  permanent: "",
                                                },
                                              },
                                              [
                                                _c(
                                                  VListItem,
                                                  [
                                                    _c(
                                                      VListItemContent,
                                                      [
                                                        _c(
                                                          VListItemTitle,
                                                          {
                                                            staticClass:
                                                              "text-h6",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                                            AnotherLabsList\n                                            "
                                                            ),
                                                            _vm.loadingForDeletedLabsList
                                                              ? _c(
                                                                  VProgressCircular,
                                                                  {
                                                                    staticStyle:
                                                                      {
                                                                        padding:
                                                                          "20px",
                                                                        margin:
                                                                          "15px",
                                                                      },
                                                                    attrs: {
                                                                      indeterminate:
                                                                        "",
                                                                      color:
                                                                        "primary",
                                                                    },
                                                                  }
                                                                )
                                                              : _vm._e(),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(VDivider),
                                                _c(
                                                  VList,
                                                  {
                                                    attrs: {
                                                      dense: "",
                                                      nav: "",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      VListItemGroup,
                                                      {
                                                        attrs: {
                                                          color: "primary",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.selectedItem,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.selectedItem =
                                                              $$v
                                                          },
                                                          expression:
                                                            "selectedItem",
                                                        },
                                                      },
                                                      _vm._l(
                                                        _vm.classInfo
                                                          .deletedLabsList,
                                                        function (delab, i) {
                                                          return _c(
                                                            VListItem,
                                                            {
                                                              key: i,
                                                              attrs: {
                                                                link: "",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.openDeletedLabCard(
                                                                      delab
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                VListItemContent,
                                                                {
                                                                  key: _vm.keyforDeletedLabsList,
                                                                },
                                                                [
                                                                  _vm
                                                                    .AllDeletedLabsList[
                                                                    delab
                                                                  ]
                                                                    ? _c(
                                                                        VListItemTitle,
                                                                        [
                                                                          _vm._v(
                                                                            "\n                                                        " +
                                                                              _vm._s(
                                                                                _vm
                                                                                  .AllDeletedLabsList[
                                                                                  delab
                                                                                ]
                                                                                  .labName
                                                                              ) +
                                                                              "\n                                                    "
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _c(
                                                                        VListItemTitle,
                                                                        [
                                                                          _vm._v(
                                                                            "\n                                                        " +
                                                                              _vm._s(
                                                                                delab
                                                                              ) +
                                                                              "\n                                                    "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        }
                                                      ),
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(VDivider),
                                              ],
                                              1
                                            )
                                          : _c("div", [
                                              !_vm.delabInfo &&
                                              !_vm.loadingDeletedLabCard
                                                ? _c(
                                                    "div",
                                                    [
                                                      _c(
                                                        VListItem,
                                                        [
                                                          _c(
                                                            VListItemContent,
                                                            [
                                                              _c(
                                                                VListItemTitle,
                                                                {
                                                                  staticClass:
                                                                    "text-h6",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                                                AnotherLabsList\n                                                "
                                                                  ),
                                                                  _vm.loadingForDeletedLabsList
                                                                    ? _c(
                                                                        VProgressCircular,
                                                                        {
                                                                          staticStyle:
                                                                            {
                                                                              padding:
                                                                                "20px",
                                                                              margin:
                                                                                "15px",
                                                                            },
                                                                          attrs:
                                                                            {
                                                                              indeterminate:
                                                                                "",
                                                                              color:
                                                                                "primary",
                                                                            },
                                                                        }
                                                                      )
                                                                    : _vm._e(),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(VDivider),
                                                      _c(
                                                        VList,
                                                        [
                                                          _c(
                                                            VListItemGroup,
                                                            {
                                                              attrs: {
                                                                color:
                                                                  "primary",
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.selectedItem,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.selectedItem =
                                                                      $$v
                                                                  },
                                                                expression:
                                                                  "selectedItem",
                                                              },
                                                            },
                                                            _vm._l(
                                                              _vm.classInfo
                                                                .deletedLabsList,
                                                              function (
                                                                delab,
                                                                i
                                                              ) {
                                                                return _c(
                                                                  VListItem,
                                                                  {
                                                                    key: i,
                                                                    attrs: {
                                                                      link: "",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.openDeletedLabCard(
                                                                            delab
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      VListItemContent,
                                                                      {
                                                                        key: _vm.keyforDeletedLabsList,
                                                                      },
                                                                      [
                                                                        _vm
                                                                          .AllDeletedLabsList[
                                                                          delab
                                                                        ]
                                                                          ? _c(
                                                                              VListItemTitle,
                                                                              [
                                                                                _vm._v(
                                                                                  "\n                                                        " +
                                                                                    _vm._s(
                                                                                      _vm
                                                                                        .AllDeletedLabsList[
                                                                                        delab
                                                                                      ]
                                                                                        .labName
                                                                                    ) +
                                                                                    "\n                                                    "
                                                                                ),
                                                                              ]
                                                                            )
                                                                          : _c(
                                                                              VListItemTitle,
                                                                              [
                                                                                _vm._v(
                                                                                  "\n                                                        " +
                                                                                    _vm._s(
                                                                                      delab
                                                                                    ) +
                                                                                    "\n                                                    "
                                                                                ),
                                                                              ]
                                                                            ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                )
                                                              }
                                                            ),
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(VDivider),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ]),
                                        !_vm.delabInfo &&
                                        _vm.loadingDeletedLabCard
                                          ? _c(VProgressCircular, {
                                              staticStyle: {
                                                padding: "20px",
                                                margin: "15px",
                                              },
                                              attrs: {
                                                indeterminate: "",
                                                color: "primary",
                                              },
                                            })
                                          : _vm._e(),
                                        _vm.delabInfo
                                          ? _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  padding: "20px",
                                                  width:
                                                    "-webkit-fill-available",
                                                },
                                              },
                                              [
                                                _c(VTextField, {
                                                  staticStyle: {
                                                    width: "200px",
                                                  },
                                                  attrs: {
                                                    filled: "",
                                                    rounded: "",
                                                    dense: "",
                                                    label: "lab-Id *",
                                                    disabled: "",
                                                  },
                                                  model: {
                                                    value: _vm.delabInfo.labId,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.delabInfo,
                                                        "labId",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "delabInfo.labId",
                                                  },
                                                }),
                                                _c(VTextField, {
                                                  staticStyle: {
                                                    width: "250px",
                                                  },
                                                  attrs: {
                                                    outlined: "",
                                                    label: "lab-Name *",
                                                    rules: _vm.nameRules,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.delabInfo.labName,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.delabInfo,
                                                        "labName",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "delabInfo.labName",
                                                  },
                                                }),
                                                _c(
                                                  "div",
                                                  [
                                                    _c(VSwitch, {
                                                      staticStyle: {
                                                        "margin-top": "-20px",
                                                      },
                                                      attrs: {
                                                        "input-value":
                                                          _vm.delabInfo
                                                            .certificate,
                                                        label: "수료 기준 설정",
                                                        color: "primary",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.onChangedMandatory(
                                                            _vm.delabInfo
                                                          )
                                                        },
                                                      },
                                                    }),
                                                    _vm.delabInfo.mandatory &&
                                                    _vm.delabInfo.certificate
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticStyle: {
                                                              "margin-top":
                                                                "-15px",
                                                              "margin-left":
                                                                "1px",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              VAutocomplete,
                                                              {
                                                                attrs: {
                                                                  label:
                                                                    "Mandatory passed",
                                                                  "auto-select-first":
                                                                    "",
                                                                  dense: "",
                                                                  solo: "",
                                                                  items:
                                                                    _vm.getMandatoryMenuLists,
                                                                  "item-text":
                                                                    "text",
                                                                  "item-value":
                                                                    "value",
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm
                                                                      .delabInfo
                                                                      .mandatory
                                                                      .checkPoint,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm
                                                                          .delabInfo
                                                                          .mandatory,
                                                                        "checkPoint",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "delabInfo.mandatory.checkPoint",
                                                                },
                                                              }
                                                            ),
                                                            _c(VCheckbox, {
                                                              staticStyle: {
                                                                "margin-top":
                                                                  "-25px",
                                                              },
                                                              attrs: {
                                                                dense: "",
                                                                label:
                                                                  "출석 여부",
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.delabInfo
                                                                    .mandatory
                                                                    .attend,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm
                                                                        .delabInfo
                                                                        .mandatory,
                                                                      "attend",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "delabInfo.mandatory.attend",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                ),
                                                _c(VTextarea, {
                                                  attrs: {
                                                    outlined: "",
                                                    label: "lab-Scenario",
                                                    height: "150",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.delabInfo.labScenario,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.delabInfo,
                                                        "labScenario",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "delabInfo.labScenario",
                                                  },
                                                }),
                                                _c(VSelect, {
                                                  staticStyle: {
                                                    width: "200px",
                                                  },
                                                  attrs: {
                                                    rules: _vm.nameRules,
                                                    items: _vm.groupNameList,
                                                    label: "group *",
                                                    required: "",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.delabInfo.groupName,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.delabInfo,
                                                        "groupName",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "delabInfo.groupName",
                                                  },
                                                }),
                                                _c(VSelect, {
                                                  staticStyle: {
                                                    width: "250px",
                                                  },
                                                  attrs: {
                                                    items: [
                                                      "event-storming",
                                                      "ide",
                                                      "kuber-ez",
                                                      "url",
                                                      "business-model-canvas",
                                                      "quiz",
                                                    ],
                                                    label: "tool *",
                                                    required: "",
                                                  },
                                                  model: {
                                                    value: _vm.delabInfo.tool,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.delabInfo,
                                                        "tool",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "delabInfo.tool",
                                                  },
                                                }),
                                                _vm.delabInfo.tool ==
                                                "event-storming"
                                                  ? _c(VTextField, {
                                                      attrs: {
                                                        label: "모델 URL 추가",
                                                        color: "primary",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.delabInfo
                                                            .modelUrl,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.delabInfo,
                                                            "modelUrl",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "delabInfo.modelUrl",
                                                      },
                                                    })
                                                  : _vm._e(),
                                                _vm.delabInfo.tool == "ide"
                                                  ? _c(VSwitch, {
                                                      attrs: {
                                                        label:
                                                          "워크스페이스 생성",
                                                        color: "primary",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.delabInfo
                                                            .addWorkSpace,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.delabInfo,
                                                            "addWorkSpace",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "delabInfo.addWorkSpace",
                                                      },
                                                    })
                                                  : _vm._e(),
                                                _c(VTextField, {
                                                  staticClass: "input-field",
                                                  staticStyle: {
                                                    width: "250px",
                                                  },
                                                  attrs: {
                                                    outlined: "",
                                                    label: "lab-Price",
                                                    disabled:
                                                      _vm.connectionKeyLength >
                                                      0,
                                                    type: "number",
                                                  },
                                                  model: {
                                                    value: _vm.delabInfo.price,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.delabInfo,
                                                        "price",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "delabInfo.price",
                                                  },
                                                }),
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      float: "right",
                                                      "margin-right": "-30px",
                                                    },
                                                    style: _vm.delabInfo
                                                      .mandatory
                                                      ? "margin-top: -55px;"
                                                      : "margin-top: 10px",
                                                  },
                                                  [
                                                    _vm.isMobile
                                                      ? _c(
                                                          VBtn,
                                                          {
                                                            staticStyle: {
                                                              "font-weight":
                                                                "700",
                                                            },
                                                            attrs: { text: "" },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                ;(_vm.delabInfo =
                                                                  null),
                                                                  (_vm.loadingDeletedLabCard = false)
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "back \n                                        "
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    _c(
                                                      VBtn,
                                                      {
                                                        staticStyle: {
                                                          "font-weight": "700",
                                                        },
                                                        attrs: {
                                                          color: "primary",
                                                          disabled:
                                                            !_vm.delabInfo
                                                              .labName,
                                                          text: "",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.backtoList(
                                                              _vm.delabInfo
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "return\n                                        "
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                              ])
                            }),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              ),
            ],
            1
          ),
          _c(VDivider),
          _vm.editModeController == "selectedLabInfo" && _vm.selectedLabInfo
            ? _c(
                VDialog,
                {
                  attrs: { persistent: "", width: "400" },
                  model: {
                    value: _vm.openDeleteDialog,
                    callback: function ($$v) {
                      _vm.openDeleteDialog = $$v
                    },
                    expression: "openDeleteDialog",
                  },
                },
                [
                  _c(
                    VCard,
                    { attrs: { height: "200" } },
                    [
                      _c(
                        VCardTitle,
                        {
                          staticStyle: {
                            "background-color": "#F44336",
                            color: "white",
                            "margin-left": "-10px",
                          },
                        },
                        [_vm._v("Delete")]
                      ),
                      _c("div", { staticStyle: { padding: "15px" } }, [
                        _vm._v(_vm._s(_vm.selectedLabInfo.labName)),
                      ]),
                      _c(VCheckbox, {
                        staticStyle: {
                          "padding-left": "12px",
                          "margin-top": "-10px",
                        },
                        attrs: {
                          "hide-details": "",
                          label: "해당 랩이 삭제됩니다.",
                          color: "red",
                          value: "red",
                        },
                        model: {
                          value: _vm.deleteCheck,
                          callback: function ($$v) {
                            _vm.deleteCheck = $$v
                          },
                          expression: "deleteCheck",
                        },
                      }),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            position: "absolute",
                            right: "5px",
                            bottom: "10px",
                          },
                        },
                        [
                          _c(
                            VBtn,
                            {
                              staticStyle: { float: "right" },
                              attrs: {
                                color: "#F44336",
                                disabled: !_vm.deleteCheck,
                                text: "",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.deleteLab(_vm.selectedLabInfo)
                                },
                              },
                            },
                            [_vm._v("삭제\n                    ")]
                          ),
                          _c(
                            VBtn,
                            {
                              staticStyle: { float: "right" },
                              attrs: { text: "" },
                              on: {
                                click: function ($event) {
                                  ;(_vm.openDeleteDialog = false),
                                    (_vm.deleteCheck = false)
                                },
                              },
                            },
                            [_vm._v("취소\n                    ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            VDialog,
            {
              attrs: { persistent: "", width: "400" },
              model: {
                value: _vm.openDeleteGroup,
                callback: function ($$v) {
                  _vm.openDeleteGroup = $$v
                },
                expression: "openDeleteGroup",
              },
            },
            [
              _c(
                VCard,
                { attrs: { height: "200" } },
                [
                  _c(
                    VCardTitle,
                    {
                      staticStyle: {
                        "background-color": "#F44336",
                        color: "white",
                        "margin-left": "-10px",
                      },
                    },
                    [_vm._v("Delete Group")]
                  ),
                  _c("div", { staticStyle: { padding: "15px" } }, [
                    _vm._v(_vm._s(_vm.deleteGroupName)),
                  ]),
                  _c(VCheckbox, {
                    staticStyle: {
                      "padding-left": "12px",
                      "margin-top": "-10px",
                    },
                    attrs: {
                      "hide-details": "",
                      label: "해당 그룹이 삭제됩니다.",
                      color: "red",
                      value: "red",
                    },
                    model: {
                      value: _vm.deleteCheckforGroup,
                      callback: function ($$v) {
                        _vm.deleteCheckforGroup = $$v
                      },
                      expression: "deleteCheckforGroup",
                    },
                  }),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        position: "absolute",
                        right: "5px",
                        bottom: "10px",
                      },
                    },
                    [
                      _c(
                        VBtn,
                        {
                          staticStyle: { float: "right" },
                          attrs: {
                            color: "#F44336",
                            disabled: !_vm.deleteCheckforGroup,
                            text: "",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.deleteGroup(
                                _vm.deleteGroupName,
                                _vm.deleteGroupIndex
                              )
                            },
                          },
                        },
                        [_vm._v("삭제\n                    ")]
                      ),
                      _c(
                        VBtn,
                        {
                          staticStyle: { float: "right" },
                          attrs: { text: "" },
                          on: {
                            click: function ($event) {
                              ;(_vm.openDeleteGroup = false),
                                (_vm.deleteCheckforGroup = false)
                            },
                          },
                        },
                        [_vm._v("취소\n                    ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            VDialog,
            {
              attrs: { persistent: "", width: "400" },
              model: {
                value: _vm.openMoveDialog,
                callback: function ($$v) {
                  _vm.openMoveDialog = $$v
                },
                expression: "openMoveDialog",
              },
            },
            [
              _c(
                VCard,
                { attrs: { height: "200" } },
                [
                  _c(
                    VCardTitle,
                    {
                      staticStyle: {
                        "background-color": "#F44336",
                        color: "white",
                        "margin-left": "-10px",
                      },
                    },
                    [_vm._v("강의 종료")]
                  ),
                  _vm.classInfo && _vm.classInfo.className
                    ? _c("div", { staticStyle: { padding: "15px" } }, [
                        _vm._v(_vm._s(_vm.classInfo.className)),
                      ])
                    : _vm._e(),
                  _c(VCheckbox, {
                    staticStyle: {
                      "padding-left": "12px",
                      "margin-top": "-10px",
                    },
                    attrs: {
                      "hide-details": "",
                      label:
                        "해당 강의가 Archive 로 이동, 종료됨을 확인하였습니다.",
                      color: "red",
                      value: "red",
                    },
                    model: {
                      value: _vm.moveCheck,
                      callback: function ($$v) {
                        _vm.moveCheck = $$v
                      },
                      expression: "moveCheck",
                    },
                  }),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        position: "absolute",
                        right: "5px",
                        bottom: "10px",
                      },
                    },
                    [
                      _c(
                        VBtn,
                        {
                          staticStyle: { float: "right" },
                          attrs: {
                            color: "#F44336",
                            disabled: !_vm.moveCheck,
                            text: "",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.moveClasstoArchive()
                            },
                          },
                        },
                        [_vm._v("확인\n                    ")]
                      ),
                      _c(
                        VBtn,
                        {
                          staticStyle: { float: "right" },
                          attrs: { text: "" },
                          on: {
                            click: function ($event) {
                              ;(_vm.openMoveDialog = false),
                                (_vm.moveCheck = false)
                            },
                          },
                        },
                        [_vm._v("취소\n                    ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.selectedLabInfo && _vm.selectedLabInfo.video
            ? _c(
                VDialog,
                {
                  attrs: {
                    "max-width": "600",
                    transition: "fab-transition",
                    "hide-overlay": !_vm.isMobile,
                  },
                  model: {
                    value: _vm.videoDialog,
                    callback: function ($$v) {
                      _vm.videoDialog = $$v
                    },
                    expression: "videoDialog",
                  },
                },
                [
                  _c(
                    VCard,
                    {
                      key: _vm.selectedLabInfo.labId,
                      style: _vm.isMobile
                        ? ""
                        : "position: absolute; bottom: 5px; right: 20px; width: 600px;",
                      attrs: { height: "400px" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            height: "34px",
                            "background-color": "white",
                          },
                        },
                        [
                          _c(
                            VBtn,
                            {
                              staticStyle: {
                                position: "absolute",
                                right: "5px",
                              },
                              attrs: { text: "", icon: "" },
                              on: {
                                click: function ($event) {
                                  _vm.videoDialog = false
                                },
                              },
                            },
                            [_c(VIcon, [_vm._v("mdi-close")])],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticStyle: { height: "90%" } },
                        [
                          _c("youtube-media", {
                            staticStyle: { width: "100%", height: "100%" },
                            attrs: { "video-id": _vm.videoId },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            VSnackbar,
            {
              attrs: { timeout: "3000", color: _vm.snackBar.Color },
              scopedSlots: _vm._u([
                {
                  key: "action",
                  fn: function ({ attrs }) {
                    return [
                      _c(
                        VBtn,
                        _vm._b(
                          {
                            attrs: { color: "white", text: "" },
                            on: {
                              click: function ($event) {
                                _vm.snackBar.show = false
                              },
                            },
                          },
                          "v-btn",
                          attrs,
                          false
                        ),
                        [
                          _vm._v(
                            "\n                    Close\n                "
                          ),
                        ]
                      ),
                    ]
                  },
                },
              ]),
              model: {
                value: _vm.snackBar.show,
                callback: function ($$v) {
                  _vm.$set(_vm.snackBar, "show", $$v)
                },
                expression: "snackBar.show",
              },
            },
            [_c("b", [_vm._v(_vm._s(_vm.snackBar.Text))])]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }